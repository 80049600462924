var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{ref:"quizTrack",staticClass:"quiz-body__track flex flex-grow",style:({
    transform: ("translateX(-" + _vm.quizTrackTranslate + "px)")
  })},[_vm._l((_vm.dynamicPages),function(page,index){return _c('quiz-page',{key:index,staticClass:"quiz-body__page",class:{
      active: _vm.currentPage === index
    },attrs:{"title":page.title,"title-class":"mb-6 sm:mb-16"}},[(page.type === _vm.pageTypes.listBox)?_c('apartment-type-page',{staticClass:"mb-4",attrs:{"apartment-types":page.values,"is-error":_vm.errors[page.id],"max-question-length":_vm.maxQuestionLength},on:{"chosen-apartments-change":function($event){return _vm.updateChosenApartments($event, index)}}}):(page.type === _vm.pageTypes.range)?_c('apartment-price-page',{attrs:{"slider-data":Object.assign({}, page.values),"is-error":_vm.errors[page.id]},on:{"update-range-pages":function($event){return _vm.updateRangePages($event, index)}}}):_vm._e()],1)}),_c('quiz-page',{staticClass:"quiz-body__page",class:{
      active: _vm.currentPage === _vm.phonePageIndex
    },attrs:{"title":_vm.phonePage.title,"title-class":"mb-6 sm:mb-16"}},[_c('form-page',{attrs:{"title":_vm.phonePage.text,"is-error":_vm.errors.phoneError},on:{"user-phone-change":function($event){return _vm.updateUserPhone($event)},"send":function($event){return _vm.$emit('send-user-data')}}})],1),_c('quiz-page',{staticClass:"quiz-body__page",class:{
      active: _vm.currentPage === _vm.successPageIndex
    },attrs:{"title":_vm.successPage.title,"title-class":"md:mt-16 mb-3 sm:mb-5 text-32px"}},[_c('success-page',{attrs:{"title":_vm.successPage.text,"success-page-image":_vm.successPageImage}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }