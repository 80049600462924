<template>
  <div class="quiz-preloader">
    <div class="quiz-preloader__loader"></div>
  </div>
</template>

<script>
export default {

}
</script>
